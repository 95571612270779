@import '../../../../../styles/colors.scss';
.settings {
	@apply cursor-pointer;
}

.faq {
	@apply mr-3 cursor-pointer;
	&:hover {
		transform: scale(1.05);
	}
}

.supportIcon {
	@apply inline-flex cursor-pointer mr-3;
	> svg:hover {
		transform: scale(1.05);
	}
}

.supportButton {
	@apply mr-2 px-2 py-1.5 text-white rounded-sm border-0 font-semibold text-sm cursor-pointer transition-colors w-auto bg-danger-300 hover:bg-danger-400;

	&:hover {
		transform: scale(1.01);
	}
}

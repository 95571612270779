.container {
	@apply w-full;
	height: 315px;

	@media (max-width: 850px) {
		height: 250px;
	}

	@media (max-width: 570px) {
		height: 220px;
	}

	@media (max-width: 410px) {
		height: 180px;
	}

	@media (max-width: 370px) {
		@apply self-center;
		width: calc(100% + 30px);
		transform: translateX(-15px);
		height: 160px;
	}
}

@import '../../../styles/colors.scss';

.headingDefault {
	@apply mb-0 mt-2 font-light text-left text-primary-800;
	font-size: 20px;
	line-height: 22px;
	color: $gray-700;
	@media (max-width: 450px) {
		font-size: 16px;
		line-height: 18px;
	}
}

.subheading {
	@apply text-base mb-1 mt-0 font-semibold;
	color: $primary-700;
}

@import '../../../../styles/colors.scss';

.container {
	@apply flex flex-col flex-1 justify-between items-center h-full bg-primary-50;
}

.presentation {
	@apply flex flex-col m-auto max-w-md w-4/5;
	max-width: 600px;
	> li {
		@apply mb-6;
	}
	> ul {
		@apply my-0 mb-0 mt-4;
		list-style-type: none;
		padding: 0;
	}
}

.heading {
	@apply mb-2 mt-2 text-4xl font-light text-left;
	color: $gray-700;
}

.description {
	@apply m-0 text-2xl pb-4 font-extralight text-left;
	color: $gray-700;
	border-bottom: 1px solid $gray-700;
}

.service {
	@apply mb-4;
	.title {
		@apply m-0 mb-1 font-extralight text-left text-gray-700;
		font-size: 18px;
		line-height: 20px;
	}

	.bullets {
		@apply inline-flex flex-wrap items-center;
		> div {
			@apply inline-flex items-center m-1 px-2 py-1 bg-gray-200 font-extralight;
			border-radius: 4px;
			> p {
				@apply m-0;
			}
			> svg {
				@apply ml-2;
			}
		}
		.youtube {
			@apply bg-gray-100 cursor-pointer box-border;
			outline: #fc0d1b 0.5px dotted;
			&:hover {
				outline: #fc0d1b 0.5px solid;
			}
		}
	}
}

@media (max-width: 800px) {
	.container {
		@apply w-full h-1/2;
		min-height: 100vh;
	}
}

@media (max-width: 430px) {
	.presentation {
		@apply pb-20;
	}
	.heading {
		@apply text-2xl;
	}
	.description {
		@apply text-xl;
	}
	.service {
		.title {
			font-size: 16px;
			line-height: 18px;
		}
		.bullets {
			> div {
				@apply text-xs;
				> p {
					@apply text-xs;
				}
			}
		}
	}
}

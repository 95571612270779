@import '../../../../styles/mixins.scss';
@import '../../../../styles/colors.scss';

.input {
	@apply w-full flex flex-col items-center justify-start h-12 mb-1 mt-4 px-2 box-border text-primary-700 text-left text-xl;
	border: 1px solid rgb(204, 204, 204);
	border-radius: 4px;
}

.error {
	@apply text-danger-500 font-semibold text-left mt-1;
	font-size: 15px;
	line-height: 15px;
}

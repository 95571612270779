.loader {
	@apply flex flex-col justify-center items-center w-full h-full;
	> p {
		@apply text-white font-thin;
	}
}

.container {
	@apply fixed top-[52px] left-0 bottom-[48px] w-full select-none;
	background-color: rgba(0, 0, 0, 0.5);
}

@import '../../../styles/colors.scss';

.group {
	@apply box-border mb-0.5 overflow-hidden;
}

.header {
	@apply box-border px-2 py-2 grid gap-1 mb-0.5 rounded-sm;
	grid-template-columns: 24px 2fr 36px 24px;
	border: solid 1px $gray-200;
	height: 36px;

	> svg,
	> div > svg {
		@apply cursor-pointer;
	}

	> input {
		@apply cursor-pointer;
	}
}

.partnerName {
	@apply whitespace-nowrap overflow-hidden overflow-ellipsis select-none;
}

.operation {
	@apply grid gap-1 box-border px-2 py-2 mb-0.5 h-9;
	grid-template-columns: 76px 98px 1fr 24px;
	border: solid 1px $gray-200;

	> div {
		@apply text-sm text-center select-none;
	}

	.description {
		@apply whitespace-nowrap overflow-hidden overflow-ellipsis select-none;
	}

	> input {
		@apply cursor-pointer;
	}
}

.container {
	@apply grid items-center mb-0.5 px-2 bg-primary-100;
	grid-template-columns: 24px 1fr 24px;
	border: solid 1px $gray-200;
	height: 36px;

	> div {
		@apply flex flex-row items-center;
		> svg {
			@apply cursor-pointer;
		}
	}

	> h2,
	h4 {
		@apply block m-0 whitespace-nowrap overflow-hidden overflow-ellipsis ml-2 text-primary-700 font-semibold select-none;
		max-width: 420px;
	}

	> button {
		@apply flex items-center justify-center p-0;
		height: 18px;
		width: 18px;
	}
}

.separating {
	grid-template-columns: 76px 98px 1fr 24px 24px;

	> svg {
		@apply cursor-pointer;
		&:hover {
			transform: scale(1.05);
		}
	}
}

.separated {
	@apply bg-neutral-50;
}

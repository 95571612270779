@import '../../../../../styles/colors.scss';

.container {
	@apply flex flex-col;
}

.title {
	@apply text-lg m-0;
}

.text {
	@apply text-base mb-1;
}

.buttons {
	@apply flex flex-row justify-between flex-wrap mt-4;
	> button {
		@apply mt-2;
	}
}

@media (max-width: 500px) {
	.title {
		@apply text-base;
	}
	.text {
		@apply text-sm;
	}
}

@media (max-width: 400px) {
	.title {
		@apply text-sm;
	}
	.text {
		@apply text-xs;
	}
	.buttons {
		> button {
			@apply text-sm px-2;
		}
	}
}

@import '../../../styles/colors.scss';

.container {
	> button {
		@apply mt-6;
	}

	> h2 {
		@apply block mb-4;
	}
}

.info {
	@apply m-0 mb-1 text-gray-500;
	> span {
		@apply text-primary-600 font-semibold m-0;
	}
	&:last-of-type {
		@apply mb-4;
	}
}

.operation {
	@apply grid gap-2 box-border items-center h-9;
	grid-template-columns: 1fr 140px 24px 24px;
	border-bottom: $gray-100 1px solid;

	> p {
		@apply text-base text-center text-primary-600 m-0 whitespace-nowrap overflow-hidden overflow-ellipsis;
	}

	> p:nth-of-type(1) {
		@apply text-left;
	}

	> input {
		@apply box-border text-center text-base w-full m-0 px-0 ml-1 text-primary-700 bg-gray-50 focus:ring-0 rounded-sm h-9;
		outline: none;
		border: none;
	}

	> svg {
		@apply cursor-pointer;
	}
}

.header {
	@apply grid gap-2 py-2;
	grid-template-columns: 1fr 140px 24px 24px;
	border-top: $gray-300 1px solid;
	border-bottom: $gray-300 1px solid;

	> p {
		@apply m-0 text-center font-semibold text-primary-700;
	}

	> p:nth-of-type(1) {
		@apply text-left;
	}
}

.error {
	@apply w-full mt-3 h-12 flex flex-row justify-center items-center bg-danger-400 text-center font-semibold text-white;
}

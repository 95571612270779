@import '../../../styles/colors.scss';

.wrapper {
	@apply flex justify-between items-center h-screen select-none;
}
.container {
	@apply flex flex-col justify-between items-center m-auto max-w-md;
}

.form {
	@apply text-center h-24 w-4/5;
}

.heading {
	@apply text-4xl font-light text-center;
	color: $gray-700;
}

.subheading {
	@apply mb-8 text-xl font-extralight text-center w-4/5;
	color: $gray-600;
}

.button {
	@apply mt-10 w-full h-10 text-white;
}

@media (max-width: 800px) {
	.heading {
		@apply text-2xl;
	}
	.subheading {
		@apply text-base;
	}
}

$primary-50: #edeff7;
$primary-100: #c9cee8;
$primary-200: #a6aed9;
$primary-300: #828dc9;
$primary-400: #5e6dba;
$primary-500: #4553a1;
$primary-600: #36417d;
$primary-700: #262e59;
$primary-800: #171c36;
$primary-900: #080912;

$secondary-50: #e9fbf8;
$secondary-100: #bef3eb;
$secondary-200: #93ecde;
$secondary-300: #93ecde;
$secondary-400: #3cdcc4;
$secondary-500: #23c3ab;
$secondary-600: #1b9885;
$secondary-700: #136c5f;
$secondary-800: #0c4139;
$secondary-900: #041613;

$gray-0: #fff;
$gray-50: #f2f2f2;
$gray-100: #d9d9d9;
$gray-200: #bfbfbf;
$gray-300: #a6a6a6;
$gray-400: #8c8c8c;
$gray-500: #737373;
$gray-600: #595959;
$gray-700: #404040;
$gray-800: #262626;
$gray-900: #0d0d0d;

$neutral-50: #fffae5;
$neutral-100: #fff6cc;
$neutral-200: #fff2b2;
$neutral-300: #ffee99;
$neutral-400: #ffe97f;
$neutral-500: #ffe14c;
$neutral-600: #ffd819;

$danger-50: #f9dee1;
$danger-100: #f2b3b9;
$danger-200: #e87d86;
$danger-300: #e25a66;
$danger-400: #df4956;
$danger-500: #db3140;
$danger-600: #b6202d;

$success-50: #ccf6c9;
$success-100: #ccf6c9;
$success-200: #aaefa5;
$success-300: #88e980;
$success-400: #66e35c;
$success-500: #45dc38;
$success-600: #2fc723;

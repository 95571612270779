.container {
	@apply grid box-border w-full  items-center bg-primary-50 p-2 mb-2 rounded-sm;
	grid-template-columns: 1fr 100px;
	height: 44px;

	> div {
		@apply grid;
		grid-template-columns: 1fr 120px;
		> p:nth-of-type(1) {
			@apply font-semibold text-primary-900 m-0 whitespace-nowrap overflow-hidden overflow-ellipsis;
		}

		> p:nth-of-type(2) {
			@apply font-semibold text-success-600 m-0;
		}
	}

	> button {
		@apply py-1 text-sm;
	}
}

.isDone {
	@apply bg-success-50;
}

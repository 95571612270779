@import '../../../../styles/colors.scss';
.adder__title {
	@apply text-center text-lg;
	line-height: 12px;
}

.adder__company {
	@apply text-center font-bold text-primary-600;
	line-height: 12px;
}

.adder__buttons {
	@apply pt-3;

	> button:nth-of-type(2) {
		@apply ml-4;
	}
}

.formContainer {
	@apply flex flex-col justify-center items-center m-6;
	> button {
		@apply mt-3;
	}
}

.form {
	outline: solid $primary-500 1px;
	max-height: 30vh;
	overflow-y: scroll;
	scrollbar-width: thin;
	-ms-overflow-style: -ms-autohiding-scrollbar;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $gray-400;
		border-radius: 0px;
	}
}

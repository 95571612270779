@import '../../../styles/colors.scss';
.landing {
	@apply flex h-screen w-screen select-none;
}

.scroll {
	@apply flex flex-col items-center justify-center bg-transparent border-none font-bold uppercase cursor-pointer;

	> p {
		@apply p-0 m-0;
		font-size: 16px;
		line-height: 18px;
		color: $primary-600;
	}
	> svg {
		@apply py-2;
		color: $primary-600;
	}
	&:hover {
		> p,
		> svg {
			transform: scale(1.02);
			color: $primary-500;
		}
	}
}

@media (max-width: 800px) {
	.landing {
		@apply flex-col;
	}
}

.overlay {
	@apply fixed top-0 left-0 h-screen w-screen select-none z-40;
	background-color: rgba(0, 0, 0, 0.5);
}

.container {
	@apply relative top-1/2 left-1/2 bg-white w-2/3 max-w-xl inline-flex justify-center items-center py-6 px-4 rounded-md z-50;
	min-height: 120px;
	max-height: 80vh;
	transform: translate(-50%, -50%);
	> div {
		width: calc(100% - 32px);
	}
	@media (max-width: 500px) {
		@apply w-3/4;
	}

	@media (max-width: 360) {
		@apply w-3/5 px-2;
		> div {
			width: calc(100% - 16px);
		}
	}
}

.closeBtn {
	@apply absolute top-0 right-0 hover:cursor-pointer;
	top: -40px;
	right: -30px;
}

.component {
	@apply z-20;
}

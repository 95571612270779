@import '../../../styles/colors.scss';

.container {
	@apply w-full flex flex-col pb-4 box-border;

	height: calc(100% - 100px);

	.children {
		@apply w-full flex flex-col box-border mb-4 px-4 box-border;
		height: calc(100% - 106px);

		> div,
		form {
			overflow-y: auto;
			scrollbar-width: thin;
			-ms-overflow-style: -ms-autohiding-scrollbar;

			&::-webkit-scrollbar {
				width: 4px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $gray-400;
				border-radius: 0px;
			}
		}
	}

	.buttons {
		@apply px-4 flex flex-row justify-between;
	}
}

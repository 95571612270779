@import '../../../../styles/colors.scss';

.container {
	@apply flex flex-col items-center box-border;
	width: 300px;
}

.uploadField {
	@apply h-full w-full flex flex-col items-center p-4 rounded-md border border-dashed box-border;
	border-color: rgb(204, 204, 204);

	&:hover {
		@apply bg-gray-50;
	}

	label {
		@apply cursor-pointer;

		span {
			@apply block text-primary-800;
			font-size: 16px;
		}

		input[type='file'] {
			@apply hidden;
		}
	}

	.dropArea {
		@apply p-4 text-center text-sm text-gray-600 box-border;
		border-radius: 4px;
		width: 100%;
	}
}
.dragOver .dropArea {
	color: $primary-500; /* Цвет текста */
}

.dragOver {
	@apply text-primary-500;
	color: $primary-500;
	border-color: $primary-500;
}

.error {
	border-color: $danger-500;

	.dropArea {
		@apply text-danger-500;
	}
}

.errorMessage {
	@apply text-danger-500 mt-2;
}

.container {
	@apply flex items-center justify-between p-2 rounded-sm hover:bg-gray-50;

	.placeholder {
		@apply mr-2;
	}

	.checkbox {
		@apply relative flex items-center cursor-pointer;

		input[type='checkbox'] {
			@apply absolute opacity-0 cursor-pointer h-0 w-0;

			&:checked ~ .checkmark {
				@apply bg-primary-500 border-primary-500;

				&:after {
					@apply flex;
				}
			}
		}

		.checkmark {
			@apply relative;
			height: 38px;
			width: 38px;
			border-radius: 4px;
			transition: background-color 0.3s;
			border: 1px solid rgb(204, 204, 204);

			&:after {
				@apply absolute hidden;
				content: '';
				position: absolute;
				left: 14px;
				top: 5px;
				width: 7px;
				height: 20px;
				border: solid white;
				border-width: 0 3px 3px 0;
				transform: rotate(45deg);
			}
		}

		&:hover input ~ .checkmark {
			@apply bg-primary-100;
		}
	}
}

@media (max-width: 450px) {
	.container {
		.placeholder {
			font-size: 14px;
			line-height: 14px;
		}

		.checkbox {
			.checkmark {
				height: 28px;
				width: 28px;

				&:after {
					left: 10px;
					top: 3px;
					width: 6px;
					height: 14px;
				}
			}
		}
	}
}

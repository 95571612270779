@import '../../../styles/colors.scss';

.container {
	min-width: 330px;
}

.header {
	@apply px-4 py-2 flex flex-row justify-between items-center bg-primary-50 box-border;
	height: 52px;
}

.menu {
	@apply flex justify-start text-primary-700 items-center;
	height: 26px;

	.menuItem {
		@apply flex flex-row items-center justify-center cursor-pointer px-2 mr-2 h-full transition-colors box-border;
		// min-width: 82px;

		> p {
			@apply m-0;
		}

		&:hover {
			border-bottom: $primary-300 solid 1px;
		}

		&.active {
			@apply font-bold bg-primary-300 text-gray-0 rounded-sm;
		}

		@media (max-width: 400px) {
			@apply text-sm;
		}
	}
}

.tools {
	@apply flex flex-row justify-between items-center;
}
